import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";

import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
const data = [
  {
    title: "Customer Visit",
    Value: 8500,
    Graph: 7000,
    Date: "June 2022",
  },
  {
    title: "Sold Projects",
    Value: 3500,
    Graph: 1000,
    Date: "July 2024",
  },
  {
    title: "Revenue",
    Value: 1500,
    Graph: 1000,
    Date: "Aug 2023",
  },
  {
    title: "Total Projects",
    Value: 9500,
    Graph: 1500,
    Date: "May 2022",
  },
];

const settings = {
  width: 150,
  height: 150,
};

const Cards = () => {
  return (
    <>
      <Box
        className="mb-3"
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          gap: 2,
        }}
      >
        {data.map((item, index) => (
          <Card key={index} variant="outlined" color="warning" invertedColors>
            <CardContent orientation="horizontal" className="m-auto d-flex">
              <Gauge
                {...settings}
                value={item.Graph}
                valueMax={item.Value}
                text={({ value, valueMax }) => `${value} / ${valueMax}`}
                cornerRadius="50%"
                sx={(theme) => ({
                  [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 15,
                  },
                  [`& .${gaugeClasses.valueArc}`]: {
                    fill: "orange",
                  },
                  [`& .${gaugeClasses.referenceArc}`]: {
                    fill: theme.palette.text.disabled,
                  },
                })}
              />
              <CardContent>
                <Typography level="body-md">{item.title}</Typography>
              </CardContent>
            </CardContent>
            <CardActions>
              <Button variant="soft" size="sm">
                {item.Date}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default Cards;
