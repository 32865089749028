import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";

import {
  Box,
  InputLabel,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import ServicesContext from "../../../ContextApi/ServiceContext";
import { BASE_URL } from "../../../Helper/Helper";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateBlogs = (props) => {
  const { datas, closeEdit, setCloseEdit, showAlert, refresh, setRefresh } =
    props;
  const navigate = useNavigate();
  const editor = useRef(null); // Use ref for the editor

  const [blogTitle, setBlogTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [publishedBy, setPublishedBy] = useState("");
  const [blogsDetails, setBlogsDetails] = useState(""); // Track the blogsDetails
  const [blogBanner, setBlogBanner] = useState(null);
  const [blogFiles, setBlogFiles] = useState([]);

  const getItems = () => {
    setBlogTitle(datas?.blogTitle);
    setBlogCategory(datas?.blogCategory);
    setPublishedBy(datas?.publishedBy);
    setBlogsDetails(datas?.blogsDetails);
    setBlogBanner(datas?.blogBanner);
    setBlogFiles(datas?.blogFiles);
  };

  const ref = useRef();
  const handleRef = () => {
    ref.current.click();
  };

  const refs = useRef();
  const handleRefs = () => {
    refs.current.click();
  };

  // Handle change
  const handleChanges = (newContent) => {
    setBlogsDetails(newContent); // Update the blogsDetails state
  };

  const { BlogsState } = useContext(ServicesContext);

  const handleBlogBanner = (event) => {
    setBlogBanner(event.target.files[0]);
  };

  const handleBlogFiles = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setBlogFiles([...blogFiles, ...updatedFiles]);
  };

  // Remove a file from the list using its index
  const handleRemoveFile = async (indexToRemove) => {
    const updatedFiles = blogFiles.filter(
      (_, index) => index !== indexToRemove
    );

    const blogGall = updatedFiles.reduce((acc, value, index) => {
      acc[index] = value;
      return acc;
    }, {});

    setBlogFiles(updatedFiles);
    await BlogsState.updateBlogsById(datas._id, blogGall);
    URL.revokeObjectURL(blogFiles[indexToRemove].preview);
  };

  const handleClose = () => {
    setCloseEdit(false);
  };

  const handleSubmit = async (e, item) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("blogTitle", blogTitle);
      formData.append("blogCategory", blogCategory);
      formData.append("publishedBy", publishedBy);
      formData.append("blogsDetails", blogsDetails);
      formData.append("blogBanner", blogBanner);

      // Add only new files
      blogFiles.forEach((file) => {
        if (file.file) {
          formData.append("blogFiles", file.file);
        } else {
          formData.append("blogFiles", file); // Existing file paths
        }
      });

      let id = item._id;
      await BlogsState.updateBlogs(id, formData);

      showAlert("success", "Blogs Updated Successfully");
      setCloseEdit(false);
      setRefresh(!refresh);
    } catch (error) {
      showAlert("danger", "OOPS!😢 Blogs fields getting error.");
    }
  };

  useEffect(() => {
    getItems();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }

    // eslint-disable-next-line
  }, [refresh, datas]);

  return (
    <>
      <Dialog
        fullScreen
        open={closeEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          className="d-flex m-auto bg-light w-100 text-align-center align-items-center justify-content-center"
        >
          Update Blog
        </DialogTitle>
        <DialogContent dividers={"body"}>
          <DialogContentText id="scroll-dialog-description">
            <div className="section-full mobile-page-padding bg-bottom-left bg-no-repeat">
              <div className="container">
                <div className="page-notfound row">
                  <div className="m-auto">
                    <Box
                      className="w-100"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <form
                        onSubmit={(e) => handleSubmit(e, datas)}
                        className="w-100 m-auto"
                      >
                        <Box
                          sx={{
                            "& .MuiTextField-root": {
                              m: 1,
                              width: "100%",
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            style={{
                              margin: "8px auto",
                              marginLeft: "5px",
                            }}
                            required
                            id="blogTitle"
                            label="Blog Title"
                            name="blogTitle"
                            autoComplete="on"
                            type="text"
                            value={blogTitle}
                            onChange={(e) => setBlogTitle(e.target.value)}
                          />

                          <TextField
                            style={{
                              margin: "8px auto",
                              marginLeft: "5px",
                            }}
                            required
                            id="eblogCategory"
                            label="Blog Category"
                            name="eblogCategory"
                            autoComplete="on"
                            type="text"
                            value={blogCategory}
                            onChange={(e) => setBlogCategory(e.target.value)}
                          />

                          {/* Incentive */}
                          <FormControl
                            sx={{
                              width: "100%",
                              textAlign: "start",
                              margin: "10px auto",
                            }}
                          >
                            <InputLabel htmlFor="Designation">
                              Published By
                            </InputLabel>
                            <Select
                              required
                              autoFocus
                              value={publishedBy}
                              onChange={(e) => setPublishedBy(e.target.value)}
                              labelId="demo-select-small-label"
                              label="publishedBy"
                              sx={{
                                // Default border styling
                                border: "1px solid #ccc",
                                // Focus border styling
                                "& .MuiSelect-select": {
                                  backgroundColor: "white",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#ccc", // Default border color
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#007bff", // Border color on hover
                                },
                              }}
                            >
                              <MenuItem disabled={true} value="Select">
                                --Select--
                              </MenuItem>
                              <MenuItem value="Admin">Admin</MenuItem>
                            </Select>
                          </FormControl>

                          <div className="mt-3 mb-2">
                            <p>Blog Overview :</p>
                            <JoditEditor
                              ref={editor}
                              value={blogsDetails} // Bind the state to the editor
                              tabIndex={1} // Tab index for textarea
                              onChange={handleChanges} // Update state on change
                            />
                          </div>

                          {/* Uploads Single Files */}
                          <div className="border border-primary-subtle p-1 mb-2">
                            <p>Blog Breadcrumbs:</p>
                            {/* Uploads Multiple Files */}
                            {datas?.blogBanner && (
                              <Avatar
                                onClick={handleRef}
                                className="d-flex m-auto pointer"
                                src={`${BASE_URL}/${datas?.blogBanner}`}
                                sx={{
                                  width: 200,
                                  height: 100,
                                  borderRadius: "0%",
                                }}
                              />
                            )}

                            <input
                              ref={ref}
                              onChange={(e) => handleBlogBanner(e)}
                              className="users-avatar d-flex m-auto w-50 mt-2"
                              type="file"
                              // required
                              id="fileavatar"
                              name="fileavatar"
                              accept="image/*"
                            />
                          </div>

                          {/* Uploads Multiple Files */}
                          <div className="border border-primary-subtle p-1 mb-2">
                            <p>More than one Blog Files:</p>

                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "16px",
                                marginTop: "16px",
                                justifyContent: "center",
                              }}
                            >
                              {blogFiles?.map((file, index) => (
                                <div className="position-relative" key={index}>
                                  <Avatar
                                    src={file.preview || `${BASE_URL}/${file}`}
                                    onClick={handleRefs}
                                    sx={{
                                      width: 200,
                                      height: 150,
                                      borderRadius: "8px",
                                      objectFit: "cover",
                                    }}
                                    className="m-1 border"
                                  />
                                  <Close
                                    title={`Remove File ${index + 1}`}
                                    onClick={() => handleRemoveFile(index)}
                                    className="position-absolute end-0 right-0 top-0 text-danger m-1 action-btn"
                                  />
                                </div>
                              ))}
                            </Box>
                            <input
                              multiple
                              ref={refs}
                              onChange={(e) => handleBlogFiles(e)}
                              className="users-avatar d-flex m-auto w-50 mt-2"
                              type="file"
                              // required
                              id="fileavatar"
                              name="fileavatar"
                              accept="image/*"
                            />
                          </div>

                          <Button
                            style={{ margin: "8px auto", width: "100%" }}
                            variant="contained"
                            size="medium"
                            type="submit"
                          >
                            Update
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            {/* SECTION CONTENT END */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="text-primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateBlogs;
