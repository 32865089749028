import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ServicesContext from "../../../ContextApi/ServiceContext";
import { Close, Description, SummarizeOutlined } from "@mui/icons-material";
import JoditEditor from "jodit-react";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#000", // Customize border color
        },
        root: {
          "&:hover fieldset": {
            borderColor: "#000", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#000", // Border color on focus
          },
        },
      },
    },
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddBlogs = (props) => {
  const { open, setOpen, setRefresh, refresh, showAlert } = props;
  const navigate = useNavigate();

  const ref = useRef();
  const refs = useRef();
  const editor = useRef(null); // Use ref for the editor

  const [blogTitle, setBlogTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [publishedBy, setPublishedBy] = useState("");
  const [blogsDetails, setBlogsDetails] = useState(""); // Track the blogsDetails
  const [blogBanner, setBlogBanner] = useState(null);
  const [blogFiles, setBlogFiles] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRef = () => {
    ref.current.click();
  };

  const handleRefs = () => {
    refs.current.click();
  };

  // Remove a file from the list using its index
  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = blogFiles.filter(
      (_, index) => index !== indexToRemove
    );

    // Revoke object URL for memory cleanup
    URL.revokeObjectURL(blogFiles[indexToRemove].preview);
    setBlogFiles(updatedFiles);
  };

  const { BlogsState } = useContext(ServicesContext);

  const handleBlogBanner = (event) => {
    setBlogBanner(event.target.files[0]);
  };

  const handleBlogFiles = (event) => {
    const files = Array.from(event.target.files);
    setBlogFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("blogTitle", blogTitle);
      formData.append("blogCategory", blogCategory);
      formData.append("publishedBy", publishedBy);
      formData.append("blogsDetails", blogsDetails);
      formData.append("blogBanner", blogBanner);

      Array.from(blogFiles).forEach((file) => {
        formData.append("blogFiles", file);
      });

      await BlogsState.addBlogs(formData);

      setOpen(false);
      showAlert("success", "Blogs Added Successfully");
      setRefresh(!refresh);
    } catch (error) {
      showAlert("danger", "OOPS!😢 Blogs fields getting error.");
    }
  };

  // Handle change
  const handleChanges = (newContent) => {
    setBlogsDetails(newContent); // Update the blogsDetails state
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          fullScreen
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            id="scroll-dialog-title"
            className="d-flex m-auto bg-light w-100 text-align-center align-items-center justify-blogsDetails-center"
          >
            Add Blog
          </DialogTitle>
          <DialogContent dividers={"body"}>
            <DialogContentText id="scroll-dialog-description">
              {/* SECTION CONTENTG START */}
              <div className="section-full mobile-page-padding bg-bottom-left bg-no-repeat">
                <div className="container">
                  <div className="page-notfound row">
                    <div className="m-auto">
                      <Box
                        className="w-100"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <form className="w-100" onSubmit={handleSubmit}>
                          <Box
                            sx={{
                              "& .MuiTextField-root": {
                                m: 1,
                                width: "100%",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              style={{
                                margin: "8px auto",
                                marginLeft: "5px",
                              }}
                              required
                              id="blogTitle"
                              label="Blog Title"
                              name="blogTitle"
                              autoComplete="on"
                              type="text"
                              value={blogTitle}
                              onChange={(e) => setBlogTitle(e.target.value)}
                            />
                            <TextField
                              style={{
                                margin: "8px auto",
                                marginLeft: "5px",
                              }}
                              required
                              id="blogCategory"
                              label="Blog Category"
                              name="blogCategory"
                              autoComplete="on"
                              type="text"
                              value={blogCategory}
                              onChange={(e) => setBlogCategory(e.target.value)}
                            />

                            {/* Incentive */}
                            <FormControl
                              sx={{
                                width: "100%",
                                textAlign: "start",
                                margin: "10px auto",
                              }}
                            >
                              <InputLabel htmlFor="Designation">
                                Published By
                              </InputLabel>
                              <Select
                                required
                                autoFocus
                                value={publishedBy}
                                onChange={(e) => setPublishedBy(e.target.value)}
                                labelId="demo-select-small-label"
                                label="publishedBy"
                                sx={{
                                  // Default border styling
                                  border: "1px solid #ccc",
                                  // Focus border styling
                                  "& .MuiSelect-select": {
                                    backgroundColor: "white",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ccc", // Default border color
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#007bff", // Border color on hover
                                  },
                                }}
                              >
                                <MenuItem disabled={true} value="Select">
                                  --Select--
                                </MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                              </Select>
                            </FormControl>

                            <div className="mt-3 mb-3">
                              <h6>Blogs Overview :</h6>
                              <JoditEditor
                                ref={editor}
                                value={blogsDetails} // Bind the state to the editor
                                tabIndex={1} // Tab index for textarea
                                onChange={handleChanges} // Update state on change
                              />
                            </div>

                            {/* Uploads Single Files */}
                            <div className="border border-primary-subtle p-1 mb-2">
                              <p>Blog Breadcrumbs:</p>
                              {blogBanner ? (
                                <Avatar
                                  onClick={handleRef}
                                  className="d-flex m-auto action-btn"
                                  src={URL.createObjectURL(blogBanner)}
                                  sx={{
                                    width: 400,
                                    height: 200,
                                    borderRadius: "0%",
                                  }}
                                />
                              ) : (
                                <Description
                                  onClick={handleRef}
                                  className="text-primary d-flex m-auto action-btn"
                                  sx={{
                                    width: 400,
                                    height: 200,
                                    borderRadius: "0%",
                                  }}
                                />
                              )}

                              <input
                                ref={ref}
                                onChange={(e) => handleBlogBanner(e)}
                                className="users-avatar d-flex m-auto w-50 mt-2"
                                type="file"
                                required
                                id="fileavatar"
                                name="fileavatar"
                                accept="image/*"
                              />
                            </div>

                            {/* Uploads Multiple Files */}
                            <div className="border border-primary-subtle p-1 mb-2">
                              <p>More than one Blog Files:</p>
                              {blogFiles?.length > 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "16px",
                                    marginTop: "16px",
                                    justifyContent: "center",
                                  }}
                                >
                                  {blogFiles?.map((file, index) => (
                                    <div
                                      className="position-relative"
                                      key={index}
                                    >
                                      <Avatar
                                        src={URL.createObjectURL(file)}
                                        onClick={handleRefs}
                                        sx={{
                                          width: 200,
                                          height: 150,
                                          borderRadius: "8px",
                                          objectFit: "cover",
                                        }}
                                        className="m-1 border"
                                      />
                                      <Close
                                        title={`Remove File ${index + 1}`}
                                        onClick={() => handleRemoveFile(index)}
                                        className="action-btn position-absolute end-0 right-0 top-0 text-danger m-1"
                                      />
                                    </div>
                                  ))}
                                </Box>
                              ) : (
                                <SummarizeOutlined
                                  onClick={handleRefs}
                                  className="text-primary d-flex m-auto action-btn"
                                  sx={{
                                    width: 400,
                                    height: 200,
                                    borderRadius: "0%",
                                  }}
                                />
                              )}

                              <input
                                multiple
                                ref={refs}
                                onChange={(e) => handleBlogFiles(e)}
                                className="users-avatar d-flex m-auto w-50 mt-2"
                                type="file"
                                required
                                id="fileavatar"
                                name="fileavatar"
                                accept="image/*"
                              />
                            </div>

                            <Button
                              style={{ margin: "8px auto", width: "100%" }}
                              variant="contained"
                              size="medium"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
              {/* SECTION CONTENT END */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="text-primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AddBlogs;
