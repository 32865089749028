import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, TextField, Grid, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Add, Mode, RestoreFromTrash } from "@mui/icons-material";
import ServicesContext from "../../../ContextApi/ServiceContext";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "../../../Helper/Helper";
import UpdateBlogs from "./UpdateBlogs";

const BlogsDataList = (props) => {
  const { setOpen, refresh, setRefresh, showAlert } = props;
  let getToken = localStorage.getItem("token");
  const decoded = jwtDecode(getToken)._doc;

  const [filterText, setFilterText] = useState("");
  const [blogsData, setBlogsData] = useState([]);

  const { BlogsState } = useContext(ServicesContext);
  const stripHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || ""; // Extracts plain text
  };

  let rows = blogsData.map((item, index) => ({
    id: index + 1,
    blogTitle: item.blogTitle,
    blogCategory: item.blogCategory,
    publishedBy: item.publishedBy,
    blogBanner: item.blogBanner,
    blogFiles: item.blogFiles,
    blogsDetails: stripHtml(item.blogsDetails),
    Action: item,
  }));

  const columns = [
    { field: "id", headerName: "Sn", width: 70 },
    { field: "blogTitle", headerName: "Blog Title", width: 250 },
    { field: "blogCategory", headerName: "Blog Category", width: 250 },
    { field: "publishedBy", headerName: "Published By", width: 180 },
    {
      field: "blogBanner",
      headerName: "Blog Breadcrumbs",
      width: 200,
      renderCell: (params) => (
        // console.log("params1", params.value),
        <img
          className="p-1"
          src={`${BASE_URL}/${params.value}`}
          alt="blogBanner"
          style={{ width: 200, height: 100 }}
        />
      ),
    },
    {
      field: "blogFiles",
      headerName: "Files",
      width: 600,
      overflow: "auto",
      renderCell: (params) => (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {params.value.map((item, index) => (
            <img
              key={index}
              className="p-1"
              src={`${BASE_URL}/${item}`}
              alt={`blogFiles- ${index}`}
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          ))}
        </div>
      ),
    },
    { field: "blogsDetails", headerName: "Profile Overview", width: 600 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <Button
            disabled={decoded.department !== "Admin"}
            className="d-flex align-items-center justify-content-start"
          >
            <Mode
              titleAccess="Edit"
              className="action-btn text-warning"
              onClick={() => handleEdit(params.row.Action)}
            />
            <RestoreFromTrash
              titleAccess="Delete"
              className="action-btn text-danger"
              onClick={() => handleDelete(params.row.Action._id)}
            />
          </Button>
        </>
      ),
      sortable: false,
      width: 100,
      valueGetter: (value, row) =>
        `${row.designation || ""} ${row.summary || ""}`,
    },
  ];

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Get Products
  const data = BlogsState.getBlogs();

  // get Data
  const getData = () => {
    data.then((res) => {
      setBlogsData(res);
    });
  };

  // handleDelete
  const handleDelete = (id) => {
    try {
      if (window.confirm("Are you sure want to delete it ?") === true) {
        BlogsState.deleteBlogs(id);
        showAlert("success", "Item Deleted Successfully");
        setRefresh(!refresh);
      }
    } catch (error) {
      showAlert("danger", "OOPS!😢 Item Delete getting Error.");
    }
  };

  const [datas, setDatas] = useState("");
  const [closeEdit, setCloseEdit] = useState(false);
  const handleEdit = (item) => {
    setCloseEdit(!closeEdit);
    setDatas(item);
  };

  useEffect(() => {
    getData();
    setRefresh(refresh);
    if (!localStorage.getItem("token")) {
      Navigate("/");
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <>
      <UpdateBlogs
        datas={datas}
        closeEdit={closeEdit}
        setCloseEdit={setCloseEdit}
        showAlert={showAlert}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <Grid style={{ overflow: "auto" }} container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" component="div">
            Blogs List
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginRight: 10 }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />

          <Button
            disabled={decoded.department !== "Admin"}
            onClick={handleClickOpen}
            title="Add User"
            className="border rounded shadow m-1 p-1"
            variant="h6"
            component="div"
          >
            <Add />
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            overflow: "auto",
            minHeight: 600,
            maxHeight: 900,
            width: 100,
          }}
        >
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 15]}
            checkboxSelection
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BlogsDataList;
