import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Cards from "../../Utilities/Cards";
import DashboardLayout from "../../DashboardLayout";
import AddBlogs from "./AddBlogs";
import BlogsDataList from "./BlogsDataList";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const BlogsList = (porps) => {
  const { showAlert } = porps;
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //   Search for Icon
  return (
    <>
      <AddBlogs
        refresh={refresh}
        setRefresh={setRefresh}
        open={open}
        setOpen={setOpen}
        showAlert={showAlert}
      />

      <DashboardLayout>
        <Box sx={{ display: "flex" }}>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Box>
              <Cards />
            </Box>

            <BlogsDataList
              refresh={refresh}
              setRefresh={setRefresh}
              showAlert={showAlert}
              setOpen={setOpen}
            />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default BlogsList;
