// Filename - components/SidebarData.js

import React from "react";

import {
  LocationCity,
  Tty,
  SpeakerNotes,
  TravelExplore,
  Ballot,
  PermContactCalendar,
  AdminPanelSettingsRounded,
  Security,
  Engineering,
  Diversity2,
  Diversity3,
  Snowshoeing,
  InterpreterMode,
  School,
  HistoryEdu,
  CastForEducation,
  TryOutlined,
} from "@mui/icons-material";

export const menuItems = [
  {
    id: 1,
    title: "Project",
    path: "/dashboard/dashboard-project",
    icon: <LocationCity className="sidebar-icon" />,
  },
  {
    id: 2,
    title: "Query",
    icon: <Tty className="sidebar-icon" />,
    subNav: [
      {
        id: 1,
        title: "Contact",
        path: "/dashboard/customer-query",
        icon: <PermContactCalendar className="sidebar-sub-icon" />,
      },
      {
        id: 2,
        title: "Visitor",
        path: "/dashboard/visitor-list",
        icon: <Snowshoeing className="sidebar-sub-icon" />,
      },
      {
        id: 3,
        title: "User",
        path: "/dashboard/visitor-user-query",
        icon: <InterpreterMode className="sidebar-sub-icon" />,
      },
      {
        id: 4,
        title: "Appointments",
        path: "/dashboard/customer-appointment",
        icon: <Ballot className="sidebar-sub-icon" />,
      },
      {
        id: 5,
        title: "Comments",
        path: "/dashboard/customer/comment-list",
        icon: <SpeakerNotes className="sidebar-sub-icon" />,
      },
    ],
  },
  {
    id: 3,
    title: "Teams",
    path: "/dashboard/teams-list",
    icon: <Diversity2 className="sidebar-icon" />,
  },
  {
    id: 4,
    title: "Blogs",
    path: "/dashboard/blogs-list",
    icon: <TryOutlined className="sidebar-icon" />,
  },
  {
    id: 5,
    title: "Seo",
    path: "/dashboard/seo",
    icon: <TravelExplore className="sidebar-icon" />,
  },
  {
    id: 6,
    title: "Career",
    icon: <School className="sidebar-icon" />,
    subNav: [
      {
        id: 1,
        path: "/dashboard/department-list",
        title: "Department",
        icon: <Diversity3 className="sidebar-sub-icon" />,
      },
      {
        id: 2,
        path: "/dashboard/job-list",
        title: "Post Job",
        icon: <CastForEducation className="sidebar-sub-icon" />,
      },
      {
        id: 3,
        path: "/dashboard/curriculum-list",
        title: "Curriculum",
        icon: <HistoryEdu className="sidebar-sub-icon" />,
      },
    ],
  },
  {
    id: 7,
    title: "Security",
    icon: <Security className="sidebar-icon" />,
    subNav: [
      {
        id: 1,
        path: "/dashboard/admin-list",
        title: "Admins",
        icon: <AdminPanelSettingsRounded className="sidebar-sub-icon" />,
      },
      {
        id: 2,
        path: "/dashboard/admin-list",
        title: "Siteengineer",
        icon: <Engineering className="sidebar-sub-icon" />,
      },
      {
        id: 3,
        title: "Clients",
        path: "/dashboard/users-list",
        icon: <Diversity2 className="sidebar-sub-icon" />,
      },
      {
        id: 4,
        title: "Users",
        path: "/dashboard/users-list",
        icon: <Diversity3 className="sidebar-sub-icon" />,
      },
    ],
  },
];
