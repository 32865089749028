import "./App.css";
import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Loader from "./components/Common/Layouts/Loader";
import BlogsList from "./components/Common/Dashboard/DashScreens/Blogs/BlogsList";

const TeamList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Team/TeamList")
);
const DepartmentList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Department/DepartmentList")
);

const CustomerDash = React.lazy(() =>
  import("./components/Common/Dashboard/Customer/CustomerDash")
);
const CustomerProfile = React.lazy(() =>
  import("./components/Common/Dashboard/Customer/CustomerProfile")
);
const UpdateProfile = React.lazy(() =>
  import("./components/Common/Dashboard/Customer/UpdateProfile")
);
const PostJobList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/PostJob/PostJobList")
);
const CareerList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Career/CareerList")
);
const GetCareer = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Career/GetCareer")
);
const NotificationList = React.lazy(() =>
  import(
    "./components/Common/Dashboard/DashScreens/Notification/NotificationList"
  )
);
const VisitorList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Visitors/VisitorList")
);
const TermsAndCondition = React.lazy(() =>
  import("./components/Pages/TermsCondition/TermsAndCondition")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/Pages/TermsCondition/PrivacyPolicy")
);
const AddCareer = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Career/AddCareer")
);
const VisitorsContactList = React.lazy(() =>
  import(
    "./components/Common/Dashboard/DashScreens/VisitorsContact/VisitorsContactList"
  )
);
const VisitorPanel = React.lazy(() =>
  import("./components/Pages/VisitorPanel")
);
const Signin = React.lazy(() => import("./components/Pages/Signin"));
const Signup = React.lazy(() => import("./components/Pages/Signup"));
const Home = React.lazy(() => import("./components/Pages/Home"));
const Login = React.lazy(() => import("./components/Common/Dashboard/Login"));
const AlertPopup = React.lazy(() => import("./components/Common/AlertPopup"));
const ContactUs = React.lazy(() => import("./components/Screens/ContactUs"));
const Services = React.lazy(() => import("./components/Screens/Services"));
const Blogs = React.lazy(() => import("./components/Screens/Blogs"));
const BlogDetails = React.lazy(() =>
  import("./components/Screens/BlogDetails")
);
const AboutUs = React.lazy(() => import("./components/Screens/AboutUs"));
const Register = React.lazy(() =>
  import("./components/Common/Dashboard/Register")
);
const DashboardLayout = React.lazy(() =>
  import("./components/Common/Dashboard/DashboardLayout")
);
const Dashboard = React.lazy(() =>
  import("./components/Common/Dashboard/Dashboard")
);
const ProjectsList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Project/ProjectsList")
);
const AdminList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Admin/AdminList")
);
const UsersList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Users/UsersList")
);
const ContactList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Contact/ContactList")
);
const AppointmentList = React.lazy(() =>
  import(
    "./components/Common/Dashboard/DashScreens/Appointment/AppointmentList"
  )
);
const CommentsList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Comments/CommentsList")
);
const SeoList = React.lazy(() =>
  import("./components/Common/Dashboard/DashScreens/Seo/SeoList")
);
const Error = React.lazy(() => import("./components/Pages/Error"));
const Projects = React.lazy(() => import("./components/Screens/Projects"));
const ProjectDetails = React.lazy(() =>
  import("./components/Screens/ProjectDetails")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
            },
            "&:hover fieldset": {
              borderColor: "blue",
            },
            "&.Mui-focused fieldset": {
              borderColor: "gray",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
            },
            "&:hover fieldset": {
              borderColor: "blue",
            },
            "&.Mui-focused fieldset": {
              borderColor: "gray",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1976d2", // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#115293", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f50057", // Border color on focus
          },
        },
      },
    },
  },
});

const App = () => {
  const [alert, setAlert] = useState(null);
  const showAlert = (type, message) => {
    setAlert({
      type: type,
      msg: message,
    });
    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <AlertPopup alert={alert} setAlert={setAlert} />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route exact path="/" element={<Home showAlert={showAlert} />} />
              <Route
                exact
                path="/about-us"
                element={<AboutUs showAlert={showAlert} />}
              />
              <Route
                path="/contact-us"
                element={<ContactUs showAlert={showAlert} />}
              />
              <Route
                exact
                path="/services"
                element={<Services showAlert={showAlert} />}
              />
              <Route
                exact
                path="/projects"
                element={<Projects showAlert={showAlert} />}
              />
              <Route
                exact
                path="/project-details/:id/:title"
                element={<ProjectDetails showAlert={showAlert} />}
              />
              <Route
                exact
                path="/blogs"
                element={<Blogs showAlert={showAlert} />}
              />
              <Route
                exact
                path="/blogs_details/:id/:title"
                element={<BlogDetails showAlert={showAlert} />}
              />
              <Route
                exact
                path="/login"
                element={<Login showAlert={showAlert} />}
              />
              {/* User Login */}
              <Route
                exact
                path="/signup"
                element={<Signup showAlert={showAlert} />}
              />
              <Route
                exact
                path="/visitor-panel"
                element={<VisitorPanel showAlert={showAlert} />}
              />

              {/* User Register */}
              <Route
                exact
                path="/signin"
                element={<Signin showAlert={showAlert} />}
              />

              <Route
                path="/register"
                element={<Register showAlert={showAlert} />}
              />
              <Route
                path="/career"
                element={<GetCareer showAlert={showAlert} />}
              />
              <Route
                path="/career_details/:id/:title"
                // path="/career_form"
                element={<AddCareer showAlert={showAlert} />}
              />

              {/* T&C_Privacy_Policy */}
              <Route
                path="/privacy"
                element={<PrivacyPolicy showAlert={showAlert} />}
              />

              <Route
                path="/terms_conditions"
                element={<TermsAndCondition showAlert={showAlert} />}
              />
              {/* Customer Panel */}
              <Route
                path="/customer/:id/panel"
                element={<CustomerDash showAlert={showAlert} />}
              />
              <Route
                path="/customer/:id/profile"
                element={<CustomerProfile showAlert={showAlert} />}
              />
              <Route
                path="/customer/:id/change-password"
                element={<UpdateProfile showAlert={showAlert} />}
              />

              {/* Dashboard path */}
              <Route
                path="/dashboard"
                element={<DashboardLayout showAlert={showAlert} />}
              />
              {/* Nested Routes inside Dashboard */}
              <Route
                path="/dashboard/dashboard-panel"
                element={<Dashboard showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/dashboard-project"
                element={<ProjectsList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/admin-list"
                element={<AdminList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/users-list"
                element={<UsersList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/customer-query"
                element={<ContactList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/visitor-user-query"
                element={<VisitorList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/visitor-list"
                element={<VisitorsContactList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/customer-appointment"
                element={<AppointmentList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/customer/comment-list"
                element={<CommentsList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/seo"
                element={<SeoList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/notifications"
                element={<NotificationList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/job-list"
                element={<PostJobList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/curriculum-list"
                element={<CareerList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/department-list"
                element={<DepartmentList showAlert={showAlert} />}
              />
              <Route
                path="/dashboard/teams-list"
                element={<TeamList showAlert={showAlert} />}
              />
              <Route
                exact
                path="/dashboard/blogs-list"
                element={<BlogsList showAlert={showAlert} />}
              />

              {/* 404 */}
              <Route
                path="/dashboard"
                element={<DashboardLayout showAlert={showAlert} />}
              />
              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
          {/* <WhatsAppContact /> */}
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
