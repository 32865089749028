import React, { useContext, useEffect, useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { menuItems } from "./DashBoardData/SidebarData";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import ServicesContext from "../ContextApi/ServiceContext";
import { BASE_URL } from "../Helper/Helper";

// drawdr tools
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashHeader = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //   Search for Icon
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure want to logout?") === true) {
      localStorage.clear();
      navigate("/login");
    }
  };

  const { AdminState } = useContext(ServicesContext);
  const data = AdminState.getUsers();
  const [userData, setUserData] = useState([]);

  // get Data
  const getData = () => {
    data.then((res) => {
      // console.log("res", res);
      setUserData(res);
    });
  };

  // get NotificationState Data
  const [notification, setNotification] = useState([]);
  // console.log("notification", notification?.length);

  const { NotificationState } = useContext(ServicesContext);
  const getNotifyData = () => {
    NotificationState.getNotification().then((res) => {
      setNotification(res);
      // console.log("notify", res);
    });
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => navigate("/dashboard/dashboard-panel")}>
        Dashboard
      </MenuItem>
      <MenuItem onClick={() => navigate("/dashboard/admin-list")}>
        My account
      </MenuItem>

      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        sx={{ outline: "none !important", border: "none !important" }}
        className="user-none"
      >
        <IconButton
          onClick={() => navigate("/dashboard/notifications")}
          className="user-none"
          size="large"
          aria-label="show 4 new mails"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem
        sx={{ outline: "none !important", border: "none !important" }}
        className="user-none"
      >
        <IconButton
          onClick={() => navigate("/dashboard/notifications")}
          className="user-none"
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge
            sx={{ outline: "none !important", border: "none !important" }}
            className="user-none"
            badgeContent={notification?.length}
            color="error"
          >
            <NotificationsIcon
              sx={{ outline: "none !important", border: "none !important" }}
              className="user-none"
            />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem
        sx={{ outline: "none !important", border: "none !important" }}
        className="user-none"
        onClick={handleProfileMenuOpen}
      >
        <IconButton
          className="user-none"
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {userData?.map((item, index) => {
            return [
              localStorage.getItem("id") === item._id && (
                <div
                  key={index}
                  role="button"
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    style={{ height: "50px", width: "50px" }}
                    title={`${item.name}`}
                    className="text-decoration-none border-0"
                  >
                    <img
                      style={{ borderRadius: "50%" }}
                      width="52px"
                      height="60px"
                      src={`${BASE_URL}/${item.profilePic}`}
                      alt="Avatar"
                      className="img-thumbnail border-0"
                    />
                  </div>
                </div>
              ),
            ];
          })}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  // Collapse drawer
  // const [drawerOpen, setDrawerOpen] = useState(true); // Drawer open state
  const [submenuOpen, setSubmenuOpen] = useState({}); // State to manage each submenu open/close

  const handleDrawerToggle = () => {
    open(!setOpen);
  };

  const handleSubmenuToggle = (key) => {
    setSubmenuOpen((prev) => (prev === key ? null : key));
  };

  const renderNestedItems = (items, parentIndex = "") => {
    return items.map((item, index) => {
      const key = parentIndex ? `${parentIndex}-${index}` : `${index}`;

      if (typeof item === "string") {
        return (
          <ListItem
            key={key}
            sx={{ pl: parentIndex ? 4 : 0, marginLeft: "10px" }}
          >
            <ListItemText primary={item} />
          </ListItem>
        );
      }

      return (
        <div key={key}>
          <ListItem
            title={item.title}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => item.subNav && handleSubmenuToggle(key)}
          >
            <ListItemButton
              onClick={() => !item.subNav && navigate(`${item.path}`)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                sx={{ opacity: open ? 1 : 0 }}
                primary={item.title}
              />
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                {item.subNav &&
                  (submenuOpen === key ? <ExpandLess /> : <ExpandMore />)}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {item.subNav && (
            <Collapse in={submenuOpen === key} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderNestedItems(item.subNav, key)}
              </List>
            </Collapse>
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    getData();
    getNotifyData();
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CssBaseline />
      <AppBar variant="soft" position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Toolbar className="d-none">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              onClick={() => navigate("/dashboard/notifications")}
              className="icon-size"
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              onClick={() => navigate("/dashboard/notifications")}
              className="icon-size"
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={notification?.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {userData?.map((item, index) => {
                return [
                  localStorage.getItem("id") === item._id && (
                    <div
                      key={index}
                      role="button"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div
                        style={{ height: "50px", width: "50px" }}
                        title={`${item.name}`}
                        className="text-decoration-none border-0"
                      >
                        <img
                          style={{ borderRadius: "50%" }}
                          src={`${BASE_URL}/${item.profilePic}`}
                          alt="Avatar"
                          className="img-thumbnail border-0 icon-size"
                        />
                      </div>
                    </div>
                  ),
                ];
              })}
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
        {renderMobileMenu}
        {renderMenu}
      </AppBar>
      <Drawer
        // variant="persistent"
        anchor="left"
        onClose={handleDrawerToggle}
        variant="permanent"
        open={open}
      >
        <DrawerHeader className="mb-4">
          <img
            title="Raipur Builder"
            style={{ height: "60px", width: "60px", cursor: "pointer" }}
            className="float-start m-auto"
            src={require("../../.././images/logo-light.png")}
            alt=""
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          {["Dashboard"].map((text, index) => (
            <ListItem
              key={index}
              title={text}
              onClick={() => navigate(`/dashboard/dashboard-panel`)}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HomeWorkIcon
                    style={{ color: "#e3a02c", fontWeight: "bold" }}
                  />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />

        <List>{renderNestedItems(menuItems)}</List>
        <Divider />
        <List>
          {["Logout"].map((text, index) => (
            <ListItem
              title={text}
              onClick={handleLogout}
              key={index}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Logout style={{ color: "#e3a02c", fontWeight: "bold" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default DashHeader;
